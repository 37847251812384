import { Routes, Route } from "react-router-dom";

import HomePage from "./pages/home/HomePage";
import AudioPage from "./pages/audio/AudioPage";
import MapPage from "./pages/map/MapPage";
import NotFoundPage from "./pages/notFound/NotFoundPage";
import ComingSoonPage from "./pages/comingSoon/ComingSoon";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/service" element={<ComingSoonPage />} />
      <Route path="/about" element={<ComingSoonPage />} />
      <Route path="/team" element={<ComingSoonPage />} />
      <Route path="/contact-us" element={<ComingSoonPage />} />
      <Route path="/audio/:audioId" element={<AudioPage />} />
      <Route path="/map/:mapId" element={<MapPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
