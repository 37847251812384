import React from "react";

import main1 from "../../assets/main1.png";

import "./intro.css";
import { IconButton } from "@mui/material";

const Intro = () => {
  return (
    <div className="intro">
      <div className="introLeft">
        <div className="introLeftMargin">
          <p>Our Introduction</p>
          <h1>
            Book Online For <br /> Doctor’s Appointment
          </h1>
          <div>
            Users can effortlessly manage appointments, track lab reports, and
            receive daily medication reminders, all in one convenient platform.
            Simplify your healthcare routine with Medica, your go-to solution
            for all health-related things.
          </div>
          {/* <button>Get Started</button> */}
          <IconButton
            sx={{
              width: "112px",
              height: "36px",
              fontSize: 12,
              color: "#3CBAA1",
              borderRadius: "18px",
              background: "white",
              ":hover": {
                background: "white",
              },
            }}
          >
            Get Started
          </IconButton>
        </div>
      </div>
      <div className="introRight">
        <img src={main1} alt="doctors" />
        <p className="introRightBtn">
          <a
            href="https://medi-ca.com/doctorpatient/"
            target="_blank"
            rel="noreferrer"
          >
            Patient/Doctor Portal
          </a>
        </p>
        <p className="introRightBtn2">
          <a href="https://lab.medi-ca.com" target="_blank" rel="noreferrer">
            Laboratory Portal
          </a>
        </p>
        <p className="introRightBtn3">
          <a
            href="https://pharmacy.medi-ca.com"
            target="_blank"
            rel="noreferrer"
          >
            Pharmacy Portal
          </a>
        </p>
        <p className="introRightBtn4">
          <a
            href="https://ambulance.medi-ca.com"
            target="_blank"
            rel="noreferrer"
          >
            Ambulance Portal
          </a>
        </p>
      </div>
    </div>
  );
};

export default Intro;
