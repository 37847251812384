import React from "react";

const ComingSoonPage = () => {
  return (
    <div className="container">
      <h1 className="container-autio">Page Coming Soon</h1>
    </div>
  );
};

export default ComingSoonPage;
